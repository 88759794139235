import React, { useRef } from "react"
import { graphql } from "gatsby"

import Img from "gatsby-image"

import Layout from "../components/Layout"
import Contact from "../components/Contact"
import styled from "styled-components"

import { Link } from "gatsby"
const Section = styled.section`
  align-self: center;

  width: 70%;
  max-width: 1200px;

  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);

  justify-content: center;
  align-items: center;

  grid-gap: 2.5em;
  margin-bottom: 10em;
`
const SectionWithHeader = styled(Section)`
  & > *:first-child {
    grid-column: span ${props => props.columns};
    text-align: center;
  }
`
const HeroSection = styled(Section)`
  width: 70%;
  height: 55vh;

  position: relative;

  display: flex;
  flex-direction: column;

  .front-img {
    position: absolute;
    width: 100%;
    height: 100%;
    /* margin: 0px 20% 0px 20%; */
    overflow: hidden;
    z-index: 1 !important;
  }

  .gatsby-image-wrapper {
    height: 100%;
  }

  & > * {
    z-index: 2;
  }

  .title {
    color: white;
    font-size: 3em;

    font-family: monument-bold;
    text-align: center;
    text-transform: uppercase;
  }

  .sub-title {
    color: white;
    font-size: 2em;

    font-family: monument;
    text-align: center;
    text-transform: uppercase;
  }
`

const HSeparator = styled(Section)`
  display: flex;
  width: 10%;
  border: 1px solid black;
`

const SectionTitle = styled.div`
  font-size: 2em;
  font-family: monument;
  text-align: center;
  text-transform: uppercase;

  padding-bottom: 2.5em;
`
const Text = styled.div`
  font-size: 1em;
  line-height: 1.25;
  font-family: monument-light;
  text-align: center;

  padding-right: 2.5em;
  padding-left: 2.5em;
  padding-bottom: 2.5em;

  ${({ padding }) => `padding: ${padding};`}
`

const MiddleSizeText = styled.div`
  font-size: 1.5em;
  line-height: 1.25;
  font-family: monument-light;
  text-align: center;

  ${props => (props.padding ? `padding: ${props.padding};` : "")}
`

const SubTitle = styled(Text)`
  font-size: 1.25em;
  font-family: monument-bold;
  text-transform: uppercase;

  // Extra small devices (portrait phones, less than 576px)
  padding-top: 50%;
  color: white;
  //color: black;

  // Extra large devices (large desktops, 1200px and up)
  /* @media (min-width: 1200px) {
    color: white;
  } */
`
const SideImage = styled.div`
  border-radius: 2em;
  overflow: hidden;
`
const SideTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const CardText = styled(Text)`
  padding-top: 2.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  align-self: flex-start;
`
const CardImg = styled.div`
  width: 10em;
  height: 10em;
  overflow: hidden;
  margin: auto;
`

const CTAWrapper = styled.div`
  display: flex;
  justify-content: ${({ justify }) => "" + justify};
  align-items: center;
`
const CTAButtonStyle = styled.button`
  margin: 0px 1em 0px 1em;
  padding: 1.25em 2.5em 1.25em 2.5em;
  border-radius: 10em; // just round
  text-transform: uppercase;
  font-size: 1.25em;
  font-family: monument;

  border: none;

  box-shadow: 0.3em 0.3em 0.3em #ccc;
  ${({ noShadow }) => (noShadow ? `box-shadow: none;` : "")}
  background: rgb(245,215,160);
  background: linear-gradient(
    133deg,
    rgba(245, 215, 160, 1) 30%,
    rgba(240, 185, 175, 1) 100%
  );

  transition: 0.5s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  a {
    text-decoration: none;
    color: black;
  }
`

const CTAButton = ({
  noShadow = false,
  justify = "center",
  style = {},
  onClick,
  children,
}) => (
  <CTAWrapper onClick={onClick} justify={justify} style={style}>
    <CTAButtonStyle noShadow={noShadow}>{children}</CTAButtonStyle>
  </CTAWrapper>
)

const Testimonial = styled(Text)`
  border-radius: 1em;
  background: rgb(241, 145, 146);
  background: linear-gradient(
    133deg,
    rgba(245, 215, 160, 1) 30%,
    rgba(240, 185, 175, 1) 100%
  );
  height: 100%;
  text-align: left;
  padding: 1.25em;
`

export default ({ data }) => {
  const letsMeetRef = useRef(null)

  return (
    <Layout
      title={
        <div>
          DOMINIKA
          <br />
          BARSKA
        </div>
      }
      fluid={true}
    >
      <HeroSection columns={1} style={{ marginBottom: "6em" }}>
        <div className="front-img">
          <Img fluid={data.hero1.childImageSharp.fluid} />
        </div>

        <span className="sub-title">Hi, I'm Dominika</span>

        <span className="title" style={{ textAlign: "left" }}>
          BARCELONA
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TOUR GUIDE
        </span>

        <CTAButton
          noShadow
          justify="flex-end"
          style={{ paddingLeft: "25%" }}
          onClick={() => letsMeetRef.current.scrollIntoView()}
        >
          Let's Meet
        </CTAButton>
      </HeroSection>
      <Section column={1}>
        <MiddleSizeText>
          I offer various tours around Barcelona. My mission is to show you this
          gorgeous city from a local perspective and make you fall in love with
          it, as I did.
        </MiddleSizeText>
      </Section>

      <SectionWithHeader columns={3}>
        {/* <SectionTitle></SectionTitle> */}
        <div></div>
        <CardImg>
          <Img fluid={data.icon1.childImageSharp.fluid} />
        </CardImg>
        <CardImg>
          <Img fluid={data.icon2.childImageSharp.fluid} />
        </CardImg>
        <CardImg>
          <Img fluid={data.icon3.childImageSharp.fluid} />
        </CardImg>
        <CardText>A professional and passionate tour guide</CardText>
        <CardText>
          Plenty of knowledge about Barcelona's history, art, culture, and daily
          life as well as tips for the rest of your stay
        </CardText>
        <CardText>Unforgettable experience and lots of fun</CardText>
      </SectionWithHeader>
      <Section columns={2}>
        <SideTextWrapper>
          <SectionTitle>WELCOME TO BARCELONA TOUR</SectionTitle>
          <Text style={{ textAlign: "left" }}>
            This tour is dedicated to those of you who have just arrived in
            Barcelona and don't know where to start. It's a quick journey
            through 2000 years of history, but don't worry.
            <br />
            I promise I will not bore you!
            <br />
            The tour is a combination of culture and entertainment.
            <br />
            We start by visiting a local food market and then we walk through a
            labyrinth of narrow winding streets of the Gothic Quarter and
            discover its secrets.
            <br />
            <br />
            Duration:
            <br />
            2h approximately
            <br />
            <br />
            Meeting point:
            <br />
            Zurich Cafe (Plaça de Catalunya 1)
            <br />
            <br />
            Price: 10 euros / person
          </Text>
          <CTAButton>
            <Link to="/coming-soon">Find out more</Link>
          </CTAButton>
        </SideTextWrapper>
        <SideImage>
          <Img fluid={data.side1.childImageSharp.fluid} />
        </SideImage>
      </Section>
      <Section columns={2}>
        <SideImage>
          <Img fluid={data.side2.childImageSharp.fluid} />
        </SideImage>
        <SideTextWrapper>
          <SectionTitle>TIBIDABO HIKE</SectionTitle>
          <Text style={{ textAlign: "right" }}>
            This is a perfect option for those of you who love active recreation
            and nature.
            <br />
            We will hike to Tibidabo - Barcelona's highest peak, located in the
            beautiful Collserola Natural Park.
            <br />
            I will tell you some exciting stories about the area along the way.
            On the top of the hill, we will see one of the oldest amusement
            parks in Europe and take a break with some spectacular views over
            the city.
            <br />
            The hike is 4 km with 400 meters gain elevation. It is an excellent
            escape from the hustle & bustle of the city center of Barcelona!
            <br />
            <br />
            Duration:
            <br />
            3h approximately
            <br />
            <br />
            Meeting point:
            <br />
            Vallcarca metro station
            <br />
            <br />
            Price: 10 euros / person
          </Text>
          <CTAButton>
            <Link to="/documentary">Find out more</Link>
          </CTAButton>
        </SideTextWrapper>
      </Section>
      <Section columns={2}>
        <SideTextWrapper>
          <SectionTitle>PHOTOGRAPHY TOUR</SectionTitle>
          <Text style={{ textAlign: "left" }}>
            This tour is dedicated not only to photographers. It is a great
            option for everyone who wants to take some cool pictures during his
            stay in Barcelona. You can either bring your camera or a smartphone.
            <br />
            I will guide you through the Old Town of Barcelona and bring you to
            amazing spots where we will photograph. I will share my knowledge
            about photography and give you lots of tips.
            <br />
            Additionally, I will be taking pictures of you which I will send you
            after the tour (of course only if you want). Let's experience
            Barcelona from the photographer's perspective!
            <br />
            <br />
            Duration:
            <br />
            1,5h approximately
            <br />
            <br />
            Meeting point:
            <br />
            Zurich Cafe (Plaça de Catalunya 1)
            <br />
            <br />
            Price: 10 euros / person
          </Text>
          <CTAButton>
            <Link to="/coming-soon">Find out more</Link>
          </CTAButton>
        </SideTextWrapper>
        <SideImage>
          <Img fluid={data.side3.childImageSharp.fluid} />
        </SideImage>
      </Section>

      <HSeparator ref={letsMeetRef} />
      <Section columns={2}>
        <SideImage>
          <Img fluid={data.side4.childImageSharp.fluid} />
        </SideImage>
        <SideTextWrapper>
          <SectionTitle>LET'S MEET</SectionTitle>
          <Text style={{ textAlign: "right" }}>
            Hi, I'm Dominika. I'm a professional Barcelona tour guide. In this
            wonderful, Mediterranean city, I'm able to work doing what I truly
            love. Being a tour guide allows me to meet from all over the world
            and share my knowledge and passion with them. The tours it offers
            are a combination of culture and entertainment. I always do my best
            to make sure that my guests not only learn something about Barcelona
            but also have a great time.
            <br />
            <br />
            If you want to experience this city with me, send me a message and
            book your tour in the form below!
          </Text>
        </SideTextWrapper>
      </Section>

      <SectionWithHeader columns={1}>
        <SectionTitle>
          Do you have any questions? Would you like to book a tour?
          <br />
          Drop me a message!
        </SectionTitle>
        <Contact />
      </SectionWithHeader>
    </Layout>
  )
}

export const query = graphql`
  query {
    side1: file(relativePath: { eq: "tours_landing/side1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    side2: file(relativePath: { eq: "tours_landing/side2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    side3: file(relativePath: { eq: "tours_landing/side3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    side4: file(relativePath: { eq: "tours_landing/side4.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hero1: file(relativePath: { eq: "tours_landing/hero1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    icon1: file(relativePath: { eq: "tours_landing/icon1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    icon2: file(relativePath: { eq: "tours_landing/icon2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    icon3: file(relativePath: { eq: "tours_landing/icon3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
